import React, { useEffect, Fragment } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { requireAuth } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import withRequiredData from './withRequiredData'
import { useConfiguration } from 'appConfiguration/configurations'
import { IdentityDetails } from 'components'
import { NotificationsPage } from 'containers/NotificationsPage'
import { AppContextProvider } from 'appContext'
import PermanentDelegations from 'components/PermanentDelegations'
import { OrgChart } from 'containers/OrgChart'
import WorkFlows from 'containers/Workflows'
import WhitePages from 'containers/WhitePages'
import { useAuthState } from 'packages/core/auth'
import { setIsWorkflowRenderable } from 'packages/core/auth/actions'
import config from 'config'

const RedirectToFirstArea = () => {
    const { dropDownOptions } = useConfiguration()
    const history = useHistory()

    useEffect(() => {
        if (dropDownOptions.length > 0) {
            history.push(dropDownOptions[0].route)
        } else {
            history.push('/forbidden')
        }
    }, [])

    return <Loader />
}

const App = () => {
    const [{ currentUserId }] = useAuthState()

    const [{ isWorkflowIRenderable }, authDispatch] = useAuthState()

    useEffect(() => {
        if (!isWorkflowIRenderable) {
            setTimeout(() => {
                authDispatch(setIsWorkflowRenderable(true))
            }, 30000)
        }
    }, [])

    return (
        <Fragment>
            {!isWorkflowIRenderable && (
                <div style={{ display: 'none' }}>
                    <iframe
                        title={`EmpowerID`}
                        src={`${config.BASE_EID_URL}/ui`}
                    />
                </div>
            )}

            <Switch>
                <Route exact path="/" component={RedirectToFirstArea} />
                <Route
                    exact
                    path="/notifications"
                    component={(props) => (
                        <AppContextProvider>
                            <NotificationsPage {...props} />
                        </AppContextProvider>
                    )}
                />
                <Route
                    exact
                    path="/orgChart/:personGuid"
                    component={(props) => {
                        const { history, match } = props
                        const personGuid = match.params.personGuid

                        const validGuidRegex = new RegExp(
                            /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
                        )
                        if (!validGuidRegex.test(personGuid)) {
                            history.push('/NotFound')
                        }

                        return (
                            <AppContextProvider>
                                <OrgChart {...props} personGuid={personGuid} />
                            </AppContextProvider>
                        )
                    }}
                />
                <Route
                    key="PermanentDelegations"
                    exact
                    path="/permanentDelegations"
                    component={(props) => (
                        <AppContextProvider>
                            <PermanentDelegations {...props} />
                        </AppContextProvider>
                    )}
                />
                <Route
                    key="Workflows"
                    exact
                    path="/workflows"
                    component={(props) => (
                        <AppContextProvider>
                            <WorkFlows {...props} />
                        </AppContextProvider>
                    )}
                />

                <Route key="whitePages" path="/whitePages">
                    <AppContextProvider>
                        <WhitePages />
                    </AppContextProvider>
                </Route>

                <Route key="myProfile" path="/myProfile">
                    <AppContextProvider>
                        <IdentityDetails personGuid={currentUserId} />
                    </AppContextProvider>
                </Route>
                <Redirect from="" to="/notfound" />
            </Switch>
        </Fragment>
    )
}

export default requireAuth(withRequiredData(App), <Loader />)
