import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Link, styled } from '@material-ui/core'
import { HorizontalTabs } from 'packages/eid-ui'
import { useIsSmallScreen } from 'packages/core'
import { ListViewIcon, CardViewIcon } from 'packages/eid-icons'
// import { useConfiguration } from 'appConfiguration'
import { useConfiguration } from 'appConfiguration/configurations'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import { DropdownMenu } from 'components/DropdownMenu'

const SubHeaderTabs = styled(HorizontalTabs)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        height: theme.mixins.navBar.minHeight,
    },
    '& .MuiTabs-indicator': {
        backgroundColor: '#307fc1',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: 'bold',
    },
}))

const SubHeader = (props) => {
    const {
        showCardView,
        toggleCardView,
        toggleFilters,
        showFilters,
        showFiltersButton,
        showBackButtonOnly,
        showBackButton,
    } = props

    const backButton = (
        <Icon
            name="BackButton"
            style={{
                cursor: 'pointer',
                position: 'absolute',
                left: 0,
                fill: '#307fc1',
            }}
            onClick={() => {
                history.goBack()
            }}
        />
    )
    const backButtonRight = (
        <Icon
            name="BackButton"
            style={{
                cursor: 'pointer',
                position: 'absolute',
                right: '10px',
                fill: '#307fc1',
            }}
            onClick={() => {
                history.goBack()
            }}
        />
    )

    const { t } = useTranslation()
    // const { areas } = useConfiguration()
    const { dropDownOptions } = useConfiguration()
    const isSmallScreen = useIsSmallScreen()
    const history = useHistory()

    const {
        location: { pathname },
    } = history
    const pathnames = pathname.split('/')
    const selectedOption = dropDownOptions.find(
        (o) => o.route === `/${pathnames[1]}`,
    )

    let activeTab = history.location.pathname

    return isSmallScreen ? (
        <></>
    ) : (
        <Box
            padding="0 0px 0 0px"
            borderBottom="1px solid rgba(0, 0, 0, 0.1)"
            height="60px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            position="relative"
            width="100%"
        >
            {showBackButtonOnly ? (
                backButton
            ) : (
                <Fragment>
                    <DropdownMenu
                        routeOptions={dropDownOptions}
                        totalCount={5}
                    />

                    {!isSmallScreen && (
                        <SubHeaderTabs
                            variant="scrollable"
                            value={activeTab}
                            onChange={(_, value) => history.push(value)}
                        >
                            {selectedOption?.tabbedOptions &&
                                selectedOption.tabbedOptions.map((op) => (
                                    <HorizontalTabs.Tab
                                        key={op.name}
                                        value={selectedOption.route + op.route}
                                        label={t(op.title)}
                                    />
                                ))}
                        </SubHeaderTabs>
                    )}

                    {isSmallScreen && showFiltersButton && (
                        <Link
                            component="button"
                            onClick={toggleFilters}
                            style={{ marginRight: '20px', minWidth: '24px' }}
                        >
                            <Icon
                                name="AdvancedSearch"
                                // color="#959598"
                                color={showFilters ? '#307FC1' : '#959598'}
                            />
                        </Link>
                    )}
                    {!isSmallScreen && toggleCardView && (
                        <Box display="flex" justifyContent="flex-end">
                            {showFiltersButton && (
                                <Link
                                    component="button"
                                    onClick={toggleFilters}
                                    style={{
                                        marginRight: '20px',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Icon
                                        name="AdvancedSearch"
                                        // color="#959598"
                                        color={
                                            showFilters ? '#307FC1' : '#959598'
                                        }
                                    />
                                </Link>
                            )}

                            <Link
                                component="button"
                                style={{
                                    marginRight: '20px',
                                    minWidth: '24px',
                                }}
                                onClick={() => toggleCardView(false)}
                            >
                                <ListViewIcon
                                    color={showCardView ? '#959598' : '#307FC1'}
                                />
                            </Link>
                            <Link
                                component="button"
                                onClick={() => toggleCardView(true)}
                                style={{ minWidth: '24px' }}
                            >
                                <CardViewIcon
                                    color={showCardView ? '#307FC1' : '#959598'}
                                />
                            </Link>
                        </Box>
                    )}

                    {showBackButton && backButtonRight}
                </Fragment>
            )}
        </Box>
    )
}

export default SubHeader
