import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, makeStyles, withStyles } from '@material-ui/core'
import { MyIdentityLayout, SubHeader } from 'components'
import BasicInfoCard from './BasicInfoCard'
import GeneralCard from './GeneralCard'
import OrganizationCard from './OrganizationCard'
import ContactCard from './ContactCard'
import LocationCard from './LocationCard'
import { useApiPut, useIdentity } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import EditModal from './EditModal'
import { config } from './data'

import CustomField from 'components/CustomField'
import { WarningModal } from 'packages/eid-ui'
import OutOfOfficeCard from './outofofficecard'
import OutOfOfficModal from './OutOfOfficModal'

const Item = withStyles({
    root: {
        padding: '0 7.5px 0 0',
        marginBottom: '40px',
    },
})(({ classes, children }) => {
    return <Box classes={classes}>{children}</Box>
})

const Column = withStyles((theme) => ({
    root: {
        flex: '50%',
        [theme.breakpoints.down(secondWrap)]: {
            flex: '100%',
        },
    },
}))(({ classes, children }) => {
    return <Box classes={classes}>{children}</Box>
})

const firstWrap = 1090
const secondWrap = 760

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        [theme.breakpoints.down(secondWrap)]: {
            flexDirection: 'column',
        },
    },
    leftColumn: {
        flex: '30%',
        [theme.breakpoints.down(firstWrap)]: {
            flex: '50%',
        },
    },
    rightColumn: {
        flex: '70%',
        [theme.breakpoints.down(firstWrap)]: {
            flex: '50%',
        },
    },
}))

const sectionDisplayNames = {
    basicInfo: 'MyIdentity_BasicInfo',
    generalInfo: 'MyIdentity_General',
    orgInfo: 'MyIdentity_Organization',
    contactInfo: 'MyIdentity_Contact',
    locationInfo: 'MyIdentity_Location',
}

const IdentityDetails = ({ personGuid, cardView }) => {
    const [personDetails, setPersonDetails] = useState(null)
    const [personDetailsSource, setPersonDetailsSource] = useState(null)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [showWarningModal, setShowWarningModal] = useState(false)
    const [response, setResponse] = useState(undefined)
    const [showOutOfOfficeModal, setShowOutOfOfficeModal] = useState(false)
    const { data, isLoading: loading, error } = useIdentity(personGuid)
    const location = useLocation()

    const { t } = useTranslation()

    useEffect(() => {
        if (data) {
            const res = data.data
            setPersonDetails({
                ...res,
                manager:
                    res.managerFirstName || res.managerLastName
                        ? `${res.managerFirstName} ${res.managerLastName}`
                        : null,
            })

            //keep static copy of orginal details
            setPersonDetailsSource({
                ...res,
                manager:
                    res.managerFirstName || res.managerLastName
                        ? `${res.managerFirstName} ${res.managerLastName}`
                        : null,
            })
        }
    }, [data])

    useEffect(() => {
        if (error && error.message === '401') {
            window.location.reload()
        }
    }, [error])

    const [updateApi, setUpdateApi] = useState(false)
    const [
        { data: updateResponse, loading: updateLoading, error: updateError },
        updatePerson,
    ] = useApiPut(`/api/v1/me/identities/${personGuid}`)

    useEffect(() => {
        if (updateResponse) {
            if (updateResponse.data && updateResponse.data.personUpdatedSuccessfully) {
                setShowWarningModal(false)
                setUnsavedChanges(false)
                setResponse({
                    success: true,
                    message: 'Successfully Updated'
                })
                setPersonDetails({
                    ...personDetails,
                    ...modalData.data,
                })
                setLoadingData(false)
            } else {
                setResponse({
                    success: false,
                    message: updateResponse.data.error ? updateResponse.data.error : "Something went wrong. Server couldn't process your data"
                })
                setLoadingData(false)
                setPersonDetails({
                    ...personDetailsSource,
                })
            }
            // Keeping commented code this for refrence

            // setResponse({
            //     success: updateResponse.isSuccess,
            //     message: updateResponse.isSuccess
            //         ? 'Successfully Updated'
            //         : updateResponse.statusMessage,
            // })

            // if (updateResponse.isSuccess) {
            //     setShowWarningModal(false)
            //     setUnsavedChanges(false)
            //     const res = updateResponse.data
            //     setPersonDetails({
            //         ...res,
            //         manager:
            //             res.managerFirstName || res.managerLastName
            //                 ? `${res.managerFirstName} ${res.managerLastName}`
            //                 : null,
            //     })
            //     setPersonDetailsSource({
            //         ...res,
            //         manager:
            //             res.managerFirstName || res.managerLastName
            //                 ? `${res.managerFirstName} ${res.managerLastName}`
            //                 : null,
            //     })
            //     setLoadingData(false)
            // } else {
            //     setPersonDetails({
            //         ...personDetailsSource,
            //     })
            //     setLoadingData(false)
            // }
        } else if (updateError) {
            setResponse({
                success: false,
                message:
                    "Something went wrong. Server couldn't process your data",
            })
            setLoadingData(false)
            setPersonDetails({
                ...personDetailsSource,
            })
        }
    }, [updateResponse, updateError])

    const handleUpdate = () => {
        setModalData({
            ...modalData,
            data: {
                ...modalData.data,
            }
        })
        setLoadingData(true)
        setUpdateApi(true)
    }

    useEffect(() => {
        if (updateApi) {
            updatePerson(bindMappingFieldsToUpdate())
            setUpdateApi(false)
            // getMyIdentity();
        }
    }, [personDetails, updateApi])

    // FC: to check modified fields to send to API
    const bindMappingFieldsToUpdate = () => {
        let modifiedData = {}
        const fields = config.find(
            (c) => c.name === modalData.section,
        ).fields

        fields.map((item) => {
            if (modalData.data[item.name] !== personDetails[item.name]) {
                const keyName = item.name;
                const keyValue = modalData.data[item.name]
                modifiedData = { ...modifiedData, [keyName]: keyValue }
            }
        })

        return modifiedData
    }

    const classes = useStyles()
    const [modalData, setModalData] = React.useState({
        open: false,
        data: undefined,
        section: undefined,
    })

    useEffect(() => {
        if (!modalData.section) {
            setUnsavedChanges(false)
        } else if (modalData.data == personDetails) {
            setUnsavedChanges(false)
        } else {
            setUnsavedChanges(false)
            const fields = config.find(
                (c) => c.name === modalData.section,
            ).fields
            fields.map((item, index) => {
                if (modalData.data[item.name] !== personDetails[item.name]) {
                    setUnsavedChanges(true)
                }
            })
        }
    }, [modalData.data, modalData.section])

    const setModalOpen = (section) => {
        setModalData({
            open: true,
            data: personDetails,
            section: section,
        })
        setResponse(undefined)
    }

    const handleOoFFieldChange = (status) => {
        if (status.key === "isOutOfOffice" && !status.value) {
            setModalData({
                ...modalData,
                data: {
                    ...modalData.data,
                    [status.key]: status.value,
                    "oofStartDate": null,
                    "oofEndDate": null,
                    "oofInternalMsg": "",
                    "oofExternalMsg": "",
                    "oofAudience": 'none',
                    "oofStatus": "Disabled",
                },
            })
        } else {
            setModalData({
                ...modalData,
                data: {
                    ...modalData.data,
                    [status.key]: status.value,
                },
            })
        }

    }

    const handleOoOUpdate = () => {
        setResponse(undefined)
        setModalData({
            ...modalData,
            data: {
                ...modalData.data,
                "oofAudience": modalData.data.isOutOfOffice && modalData.data.oofAudience ? modalData.data.oofAudience : 'none',
                "oofInternalMsg": modalData.data.oofInternalMsg && modalData.data.oofInternalMsg ? modalData.data.oofInternalMsg : `${t('MyIdentity_OoOInternalMsg')} ${modalData.data.mobilePhone ? modalData.data.mobilePhone : '+1988888xxxx'}`,
                "oofExternalMsg": modalData.data.oofAudience && modalData.data.oofAudience === "none" ? "" : modalData.data.oofExternalMsg,
                "oofStartDate": modalData.data.oofStatus && modalData.data.oofStatus !== "Scheduled" ? null : modalData.data.oofStartDate,
                "oofEndDate": modalData.data.oofStatus && modalData.data.oofStatus !== "Scheduled" ? null : modalData.data.oofEndDate
            },
        })

        setLoadingData(true)
        setUpdateApi(true)
    }

    const EditModalBody = () => {
        const fields = config.find((c) => c.name === modalData.section).fields
        const state = undefined
        const { t } = useTranslation()
        const handleChange = (input) => {
            setModalData({
                ...modalData,
                data: {
                    ...modalData.data,
                    [input.target.name]: input.target.value,
                },
            })
        }

        const handleTimeZoneChange = (timezone) => {
            setModalData({
                ...modalData,
                data: {
                    ...modalData.data,
                    globalTimeZoneID: timezone.globalTimeZoneID,
                    globalTimeZone: timezone.displayName
                }
            })
        }

        return (
            <Grid container spacing={3}>
                {fields.map((item, index) => {
                    switch (item.type) {
                        case 'InputField':
                            return (
                                <Grid item key={item.name}>
                                    <Box key={item.name}>
                                        <CustomField
                                            error={item.required}
                                            single
                                            label={t(item.label)}
                                            value={modalData.data[item.name]}
                                            name={item.name}
                                            disabled={
                                                item.disabled ? true : false
                                            }
                                            onChange={handleChange}
                                            style={item.style}
                                        />
                                    </Box>
                                </Grid>
                            )
                        case 'SelectField':
                            return (
                                <Grid item key={item.name}>
                                    <Box key={item.name}>
                                        <CustomField
                                            error={item.required}
                                            select
                                            label={t(item.label)}
                                            options={item.options}
                                            value={modalData.data[item.name]}
                                            name={item.name}
                                            disabled={
                                                item.disabled ? true : false
                                            }
                                            onChange={handleChange}
                                            style={item.style}
                                        />
                                    </Box>
                                </Grid>
                            )

                        case 'SelectTimeZoneField' : 
                            return(
                                <Grid item key={item.name}>
                                    <Box key={item.name}>
                                        <CustomField
                                            selectTimeZone
                                            label={t(item.label)}
                                            value={modalData.data[item.name]}
                                            name={item.name}
                                            disabled={
                                                item.disabled ? true : false
                                            }
                                            onChange={handleTimeZoneChange}
                                            style={item.style}
                                        />
                                    </Box>
                                </Grid>

                            )

                        case 'DatePicker':
                            return (
                                <Grid item key={item.name}>
                                    <Box key={item.id}>
                                        <CustomField
                                            date
                                            error={item.required}
                                            label={t(item.label)}
                                            value={modalData.data[item.name]}
                                            name={item.name}
                                            disabled={
                                                item.disabled ? true : false
                                            }
                                            onChange={handleChange}
                                            style={item.style}
                                        />
                                    </Box>
                                </Grid>
                            )

                        default:
                            return <></>
                    }
                })}
            </Grid>
        )
    }

    const confirmClose = () => {
        if (unsavedChanges) {
            setShowWarningModal(true)
        } else {
            if (modalData.section === "outOfOffice") {
                setShowOutOfOfficeModal(false)
            } else {
                setModalData({ section: '', open: false })
            }
        }
    }

    return (
        <MyIdentityLayout
            subHeader={
                <SubHeader
                    showBackButtonOnly={location.pathname !== '/myProfile'}
                />
            }
        >
            <Box
                marginTop="20px"
                width="100%"
                paddingX="10px"
                paddingTop="80px"
            >
                <Box display="flex" className={classes.mainContainer}>
                    {personDetails && (
                        <>
                            <div className={classes.leftColumn}>
                                <Item>
                                    <BasicInfoCard
                                        personGuid={personGuid}
                                        basicInfo={personDetails}
                                        config={config.find(
                                            (c) => c.name === 'basicInfo',
                                        )}
                                    />

                                    <OutOfOfficeCard
                                        oofInfo={personDetails}
                                        config={config.find(
                                            (c) => c.name === 'outOfOffice',
                                        )}
                                        handleModalOpen={() => {
                                            setModalData({
                                                open: false,
                                                data: personDetails,
                                                section: "outOfOffice",
                                            })
                                            setResponse(undefined)
                                            setShowOutOfOfficeModal(true)
                                        }

                                        }
                                    />
                                </Item>
                            </div>
                            <div className={classes.rightColumn}>
                                <Box display="flex" flexWrap="wrap">
                                    <Column>
                                        <Item>
                                            <GeneralCard
                                                generalInfo={personDetails}
                                                config={config.find(
                                                    (c) =>
                                                        c.name ===
                                                        'generalInfo',
                                                )}
                                                handleModalOpen={() =>
                                                    setModalOpen('generalInfo')
                                                }
                                            />
                                        </Item>
                                        <Item>
                                            <OrganizationCard
                                                organizationInfo={personDetails}
                                                config={config.find(
                                                    (c) => c.name === 'orgInfo',
                                                )}
                                                handleModalOpen={() =>
                                                    setModalOpen('orgInfo')
                                                }
                                            />
                                        </Item>
                                    </Column>
                                    <Column>
                                        <Item>
                                            <ContactCard
                                                contactInfo={personDetails}
                                                config={config.find(
                                                    (c) =>
                                                        c.name ===
                                                        'contactInfo',
                                                )}
                                                handleModalOpen={() =>
                                                    setModalOpen('contactInfo')
                                                }
                                            />
                                        </Item>
                                        <Item>
                                            <LocationCard
                                                locationInfo={personDetails}
                                                config={config.find(
                                                    (c) =>
                                                        c.name ===
                                                        'locationInfo',
                                                )}
                                                handleModalOpen={() =>
                                                    setModalOpen('locationInfo')
                                                }
                                            />
                                        </Item>
                                    </Column>
                                </Box>
                            </div>
                        </>
                    )}
                    {modalData.open && (
                        <EditModal
                            open={modalData.open}
                            onClose={confirmClose}
                            header={sectionDisplayNames[modalData.section]}
                            body={EditModalBody}
                            loading={loadingData}
                            updateData={handleUpdate}
                            handleDiscard={() =>
                                setModalOpen(modalData.section)
                            }
                            unsavedChanges={unsavedChanges}
                            response={response}
                        />
                    )}
                    {showOutOfOfficeModal && (
                        <OutOfOfficModal
                            open={showOutOfOfficeModal}
                            onClose={() => setShowOutOfOfficeModal(false)}
                            loading={loadingData}
                            updateData={handleOoOUpdate}
                            handleDiscard={() =>
                                confirmClose()
                            }
                            oofInfo={modalData.data}
                            handleOoFFieldChange={(e) => handleOoFFieldChange(e)}
                            unsavedChanges={unsavedChanges}
                            response={response}
                            setResponse={() => setResponse(undefined)}
                        />
                    )}

                    {showWarningModal && (
                        <WarningModal
                            open={showWarningModal}
                            title={t('MyIdentity_Confirm')}
                            description={t(
                                'MyIdentity_ModalClosingWarningText',
                            )}
                            proceed={() => {
                                setShowWarningModal(false)
                                if (modalData.section === "outOfOffice") {
                                    setShowOutOfOfficeModal(false)
                                } else {
                                    setModalData({ section: '', open: false })
                                }
                            }}
                            cancel={() => setShowWarningModal(false)}
                            yesLabel={t('MyIdentity_Yes')}
                            noLabel={t('MyIdentity_No')}
                        />
                    )}
                </Box>
            </Box>
        </MyIdentityLayout>
    )
}

IdentityDetails.propTypes = {
    personGuid: PropTypes.string.isRequired,
}

export default IdentityDetails
