import { ExportRegular, FilterRegular, VerticalSquare } from 'images'
import {
    isNilOrEmpty,
    useGetPagesAccess,
    useGetControlsAccess,
} from 'packages/core'

export const configuration = {
    sections: [
        {
            name: 'MyIdentities',
            title: 'MyIdentity_MyIdentities',
            route: '/identities',
            requireAccess: {
                page: 'MyIdentity-MyIdentities-Page',
            },
            order: 1,
            dataSource: {
                type: 'MS_DS',
                url: '/api/v1/me/identities',
            },
            attributes: [
                {
                    name: 'pathToImage',
                    hideValue: true,
                    src: 'pathToImage',
                    label: '',
                    icons: [FilterRegular, ExportRegular],
                    align: 'center',
                    avatar: true,
                    sort: false,
                },
                {
                    name: 'firstName',
                    label: 'MyIdentity_FirstName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: true,
                    link: true,
                    styles: {
                        fontSize: '15px',
                        fontWeight: 'bold',
                        color: '#307fc1',
                    },
                },
                {
                    name: 'lastName',
                    label: 'MyIdentity_LastName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: true,
                    styles: {
                        fontSize: '15px',
                    },
                },
                {
                    name: 'company',
                    label: 'MyIdentity_CompanyName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: false,
                },
                {
                    name: 'email',
                    label: 'MyIdentity_Email',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: false,
                    styles: {
                        textDecoration: 'underline',
                    },
                },
                {
                    name: 'viewProfileButton',
                    label: '',
                    icon: '',
                    align: 'center',
                    sort: false,
                    hideValue: true,
                    component: {
                        name: 'ViewProfileButton',
                    },
                },
            ],
        },
        {
            name: 'AllPeople',
            title: 'MyIdentity_AllPeople',
            route: '/people',
            requireAccess: {
                page: 'MyIdentity-AllPeople-Page',
            },
            order: 2,
            dataSource: {
                type: 'MS_DS',
                url: '/api/v1/people/identities',
            },
            attributes: [
                {
                    name: 'pathToImage',
                    label: '',
                    icons: [FilterRegular, ExportRegular],
                    align: 'center',
                    hideValue: true,
                    avatar: true,
                    src: 'pathToImage',
                    sort: false,
                },
                {
                    name: 'firstName',
                    label: 'MyIdentity_FirstName',
                    link: true,
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: true,
                    styles: {
                        fontSize: '15px',
                        fontWeight: 'bold',
                        color: '#307fc1',
                    },
                },
                {
                    name: 'lastName',
                    label: 'MyIdentity_LastName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: true,
                    styles: {
                        fontSize: '15px',
                    },
                },
                {
                    name: 'company',
                    label: 'MyIdentity_CompanyName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: false,
                },
                {
                    name: 'email',
                    label: 'MyIdentity_Email',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: false,
                    styles: {
                        textDecoration: 'underline',
                    },
                },
                {
                    name: 'viewProfileButton',
                    label: '',
                    icon: '',
                    align: 'center',
                    sort: false,
                    hideValue: true,
                    component: {
                        name: 'ViewProfileButton',
                    },
                },
            ],
        },
        {
            name: 'MyDirectReports',
            title: 'MyIdentity_MyDirectReports',
            route: '/directReports',
            requireAccess: {
                page: 'MyIdentity-MyDirectReports-Page',
            },
            order: 3,
            dataSource: {
                type: 'MS_DS',
                url: '/api/v1/people/directReport',
            },
            attributes: [
                {
                    name: 'pathToImage',
                    hideValue: true,
                    src: 'pathToImage',
                    label: '',
                    icons: [FilterRegular, ExportRegular],
                    align: 'center',
                    avatar: true,
                    sort: false,
                },
                {
                    name: 'firstName',
                    label: 'MyIdentity_FirstName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: true,
                    link: true,
                    styles: {
                        fontSize: '15px',
                        fontWeight: 'bold',
                        color: '#307fc1',
                    },
                },
                {
                    name: 'lastName',
                    label: 'MyIdentity_LastName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: true,
                    styles: {
                        fontSize: '15px',
                    },
                },
                {
                    name: 'company',
                    label: 'MyIdentity_CompanyName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: false,
                },
                {
                    name: 'email',
                    label: 'MyIdentity_Email',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: false,
                    styles: {
                        textDecoration: 'underline',
                    },
                },
                {
                    name: 'viewProfileButton',
                    label: '',
                    icon: '',
                    align: 'center',
                    sort: false,
                    hideValue: true,
                    component: {
                        name: 'ViewProfileButton',
                    },
                },
            ],
        },
        {
            name: 'MyDepartment',
            title: 'MyIdentity_MyDepartment',
            route: '/department',
            requireAccess: {
                page: 'MyIdentity-MyDepartment-Page',
            },
            order: 4,
            dataSource: {
                type: 'MS_DS',
                url: '/api/v1/me/departmentPeople',
            },
            attributes: [
                {
                    name: 'pathToImage',
                    hideValue: true,
                    src: 'pathToImage',
                    label: '',
                    icons: [FilterRegular, ExportRegular],
                    align: 'center',
                    avatar: true,
                    sort: false,
                },
                {
                    name: 'firstName',
                    label: 'MyIdentity_FirstName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: true,
                    link: true,
                    styles: {
                        fontSize: '15px',
                        fontWeight: 'bold',
                        color: '#307fc1',
                    },
                },
                {
                    name: 'lastName',
                    label: 'MyIdentity_LastName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: true,
                    styles: {
                        fontSize: '15px',
                    },
                },
                {
                    name: 'company',
                    label: 'MyIdentity_CompanyName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: false,
                },
                {
                    name: 'email',
                    label: 'MyIdentity_Email',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: false,
                    styles: {
                        textDecoration: 'underline',
                    },
                },
                {
                    name: 'viewProfileButton',
                    label: '',
                    icon: '',
                    align: 'center',
                    sort: false,
                    hideValue: true,
                    component: {
                        name: 'ViewProfileButton',
                    },
                },
            ],
        },
        {
            name: 'Internals',
            title: 'MyIdentity_Internals',
            route: '/internals',
            requireAccess: {
                page: 'MyIdentity-Internals-Page',
            },
            order: 5,
            dataSource: {
                type: 'MS_DS',
                url: '/api/v1/people/internal',
            },
            attributes: [
                {
                    name: 'pathToImage',
                    hideValue: true,
                    src: 'pathToImage',
                    label: '',
                    icons: [FilterRegular, ExportRegular],
                    align: 'center',
                    avatar: true,
                    sort: false,
                },
                {
                    name: 'firstName',
                    label: 'MyIdentity_FirstName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: true,
                    link: true,
                    styles: {
                        fontSize: '15px',
                        fontWeight: 'bold',
                        color: '#307fc1',
                    },
                },
                {
                    name: 'lastName',
                    label: 'MyIdentity_LastName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: true,
                    styles: {
                        fontSize: '15px',
                    },
                },
                {
                    name: 'company',
                    label: 'MyIdentity_CompanyName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: false,
                },
                {
                    name: 'email',
                    label: 'MyIdentity_Email',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: false,
                    styles: {
                        textDecoration: 'underline',
                    },
                },
                {
                    name: 'viewProfileButton',
                    label: '',
                    icon: '',
                    align: 'center',
                    sort: false,
                    hideValue: true,
                    component: {
                        name: 'ViewProfileButton',
                    },
                },
            ],
        },
        {
            name: 'Externals',
            title: 'MyIdentity_Externals',
            route: '/externals',
            requireAccess: {
                page: 'MyIdentity-Externals-Page',
            },
            order: 6,
            dataSource: {
                type: 'MS_DS',
                url: '/api/v1/people/external',
            },
            attributes: [
                {
                    name: 'pathToImage',
                    hideValue: true,
                    src: 'pathToImage',
                    label: '',
                    icons: [FilterRegular, ExportRegular],
                    align: 'center',
                    avatar: true,
                    sort: false,
                },
                {
                    name: 'firstName',
                    label: 'MyIdentity_FirstName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: true,
                    link: true,
                    styles: {
                        fontSize: '15px',
                        fontWeight: 'bold',
                        color: '#307fc1',
                    },
                },
                {
                    name: 'lastName',
                    label: 'MyIdentity_LastName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: true,
                    styles: {
                        fontSize: '15px',
                    },
                },
                {
                    name: 'company',
                    label: 'MyIdentity_CompanyName',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: false,
                },
                {
                    name: 'email',
                    label: 'MyIdentity_Email',
                    icon: VerticalSquare,
                    align: 'left',
                    sort: true,
                    asc: false,
                    styles: {
                        textDecoration: 'underline',
                    },
                },
                {
                    name: 'viewProfileButton',
                    label: '',
                    icon: '',
                    align: 'center',
                    sort: false,
                    hideValue: true,
                    component: {
                        name: 'ViewProfileButton',
                    },
                },
            ],
        },

        {
            name: 'PermanentDelegations',
            title: 'MyIdentity_PermanentDelegations',
            route: '/permanentDelegations',
            requireAccess: {
                page: 'MyIdentity-PermanentDelegations-Page',
            },
            order: 7,
        },
        {
            name: 'Workflows',
            title: 'MyIdentity_Workflows',
            route: '/workflows',
            requireAccess: {
                page: 'MyIdentity-Workflows-Page',
            },
            order: 8,
        },
    ],
}

export const useConfiguration = () => {
    const pages = useGetPagesAccess()
    const controls = useGetControlsAccess().map((c) => c.name)

    const areas = configuration.sections.filter(
        (r) =>
            isNilOrEmpty(r.requireAccess?.page) ||
            pages.findIndex((x) => x.name === r.requireAccess.page) > -1,
    )

    const hasAccessToNotificationSettings = pages
        .map((p) => p.name)
        .includes('MyIdentity-NotificationsSettings-Page')

    const hasAccessToDetailedNotificationSettings = controls.includes(
        'MyIdentity-DetailedNotificationsSettings-Control',
    )

    const canSeeDigestEmailNotificationSettings = controls.includes(
        'MyIdentity-DigestEmailNotificationsSettings-Control',
    )

    const canSeeFunctionsAndRisksNotificationSettings = controls.includes(
        'MyIdentity-FunctionsAndRisksNotificationsSettings-Control',
    )

    return {
        areas,
        hasAccessToNotificationSettings,
        hasAccessToDetailedNotificationSettings,
        canSeeDigestEmailNotificationSettings,
        canSeeFunctionsAndRisksNotificationSettings,
    }
}
