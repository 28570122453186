import React, { useState } from 'react'
import { MyIdentityLayout } from 'components'
import { useAppState } from 'appContext'
import * as DynamicComponents from 'components/DynamicComponents'
import { useTranslation } from 'react-i18next'

import { Box, Grid, Paper, styled } from '@material-ui/core'
import SubHeader from 'components/SubHeader'
import { useViewIndicator, WorkflowIFrame } from 'packages/eid-ui'
import { useHistory } from 'react-router'
import { useIsSmallScreen, useQuery, useGetControlsAccess } from 'packages/core'
import { useWorkflows } from 'hooks/workflowHooks'
import { Skeleton } from '@material-ui/lab'
import WorkFlowsItems from './WorkflowItems.tsx'
import './workFlow.css'
import config from 'config'

const WorkFlows = (route, dataSource, attributes, filters, subHeaderButton) => {
    const { t } = useTranslation()
    const query = useQuery()

    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const { data, isLoading } = useWorkflows()
    const workflowName = query.get('workflowName')
    const resourceID = query.get('resourceID')
    const wfParams = []
    if (resourceID) {
        wfParams.push(`SelectedResourceID=${resourceID}`)
    }

    const [openFilters, setOpenFilters] = useState(false)

    const controls = useGetControlsAccess().map((c) => c.name)

    const hasAccessToAdvancedSearchFilters =
        filters && controls.includes(filters[0].requireAccess?.control)

    const renderComponent = (name) => {
        const DynamicComponentToRender = DynamicComponents[name]

        return <DynamicComponentToRender />
    }

    const subHeaderButtonComponent =
        subHeaderButton &&
        (controls.includes(subHeaderButton.requireAccess.control) ? (
            renderComponent(subHeaderButton.name)
        ) : (
            <></>
        ))

    return (
        <MyIdentityLayout
            subHeader={
                <SubHeader
                    showNavTabs={true}
                    subHeaderButton={subHeaderButtonComponent}
                    showFilters={openFilters}
                    toggleFilters={() => setOpenFilters((prev) => !prev)}
                    showFiltersButton={hasAccessToAdvancedSearchFilters}
                    showBackButton={workflowName}
                    
                />
            }
        >
            <Paper className={'root workflow'}>
                <Grid container style={{justifyContent:'start' , gap:'24px'}}>
                    {workflowName ? (
                        <WorkflowIFrame
                            baseEidUrl={config.BASE_EID_URL}
                            workflowName={workflowName}
                            onComplete={() => {
                                query.delete('workflowName')
                                history.push(
                                    history.location.pathname,
                                    query.toString(),
                                )
                            }}
                            workflowParams={wfParams}
                            height={'60vh'}
                        />
                    ) : (
                        <>
                            {isLoading && !data ? (
                                <Box width={'100%'}>
                                    <Skeleton height={100} />
                                </Box>
                            ) : data?.length > 0 ? (
                                data.map((item, index) => (
                                    <WorkFlowsItems
                                        key={`workflow-item${index}`}
                                        title={item.friendlyName}
                                        description={item.description}
                                        isEnabled={item.requestWorkflowName}
                                        onClick={() => {
                                            item.requestWorkflowName
                                                ? history.push(
                                                      `${history.location.pathname}?workflowName=${item.requestWorkflowName}`,
                                                  )
                                                : history.push(
                                                      `${history.location.pathname}`,
                                                  )
                                        }}
                                    />
                                ))
                            ) : (
                                //TODO: Need to update with locale
                                <div>No work flow found</div>
                            )}
                        </>
                    )}
                </Grid>
            </Paper>
        </MyIdentityLayout>
    )
}
export default WorkFlows
