import React from 'react'
import { Icon } from '@material-ui/core'
import { Divider } from 'packages/eid-ui'
import { EmailIcon } from 'packages/eid-icons'
import GenericCard from './Cards/GenericCard'
import GenericCardHeader from './Cards/GenericCardHeader'
import GenericCardContent from './Cards/GenericCardContent'
import CardFieldGroup from './Cards/CardFieldGroup'
import CardField from './Cards/CardField'
import { useTranslation } from 'react-i18next'

const ContactCard = ({ contactInfo, handleModalOpen, config }) => {
    const { t } = useTranslation()
    return (
        <GenericCard
            onClick={config.editable ? handleModalOpen : undefined}
            editable={config.editable}
        >
            <GenericCardHeader
                backgroundColor="#fbfbfd"
                color="#b4b4b4"
                icon={
                    <Icon style={{ margin: '0.75em' }}>
                        <EmailIcon />
                    </Icon>
                }
                heading={t('MyIdentity_Contact')}
                height={60}
            />
            <GenericCardContent>
                <CardField
                    label={t('MyIdentity_Email')}
                    value={contactInfo.email}
                    borderBelow
                    singleField
                    link
                />
                <CardFieldGroup>
                    <CardField
                        label={t('MyIdentity_Telephone')}
                        value={contactInfo.telephone}
                    />
                    <Divider vertical width={23} />
                    <CardField
                        label={t('MyIdentity_MobilePhone')}
                        value={contactInfo.mobilePhone}
                    />
                </CardFieldGroup>
            </GenericCardContent>
        </GenericCard>
    )
}

export default ContactCard
