import React, { useState } from 'react'
import { Icon } from 'packages/eid-icons'
import DropdownFilter from './DropdownFilter'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

const DropdownMenu = ({ routeOptions, onChange }) => {
    const { t } = useTranslation()
    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const [open, setOpen] = useState(false)
    const pathnames = pathname.split('/')
    const selectedOption = routeOptions.find(
        (o) => o.route === `/${pathnames[1]}`,
    )

    return (
        <>
            <DropdownFilter
                label={t(selectedOption?.title)}
                setOpen={setOpen}
                open={open}
                style={{ height: '60px' }}
                // anchorPosition={{ top: '60px' }}
            >
                {routeOptions.map((routeOption, index) => (
                    <DropdownFilter.Item
                        selected={routeOption.name === `${selectedOption.name}`}
                        onClick={(e) => {
                            setOpen((prev) => !prev)
                            history.push(`${routeOption.route}`)
                        }}
                        key={index}
                    >
                        {routeOption.icon && (
                            <Icon name={routeOption.icon} color="#919193" />
                        )}
                        {routeOption.title
                            ? t(routeOption.title)
                            : 'routeOption.title'}
                    </DropdownFilter.Item>
                ))}
            </DropdownFilter>
        </>
    )
}

export { DropdownMenu }
